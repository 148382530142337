<template>
  <div>
    <div v-if="eula">
      <v-row>
        <v-col cols="12" sm="auto">
          <h1 class="text-left">EULA {{ eula.name }}</h1>
        </v-col>
        <v-col cols="12" sm="auto">
          <v-btn :block="$vuetify.breakpoint.mobile" depressed class="align-self-center mt-2 ml-md-4 mb-3 mb-md-0" @click="editEula">
            <v-icon left>mdi-pencil-outline</v-icon>
            Edit
          </v-btn>
          <v-btn
            :block="$vuetify.breakpoint.mobile"
            :disabled="eula.enabled"
            color="error"
            outlined
            depressed
            class="align-self-center mt-2 ml-md-4 mb-3 mb-md-0"
            @click="deleteEula"
          >
            <v-icon left>mdi-delete</v-icon>
            Delete
          </v-btn>
        </v-col>
      </v-row>

      <v-simple-table dense class="my-4">
        <tbody>
          <tr>
            <td class="font-weight-bold">Id:</td>
            <td>{{ eula.id }}</td>
          </tr>
          <tr>
            <td class="font-weight-bold">Name:</td>
            <td>{{ eula.name }}</td>
          </tr>
          <tr>
            <td class="font-weight-bold">Enabled:</td>
            <td>
              <readonly-checkbox :value="eula.enabled" />
            </td>
          </tr>
          <tr>
            <td class="font-weight-bold">Last updated:</td>
            <td><last-updated-field :item="eula" /></td>
          </tr>
        </tbody>
      </v-simple-table>

      <v-btn depressed @click="download">
        <v-icon left>mdi-script-text-outline</v-icon>
        Download EULA
      </v-btn>
    </div>
    <create-edit-eula ref="CreateEditEulaRef" @item-updated="bubbleEmit" />
  </div>
</template>

<script>
import apiService from '@/services/apiService';
import CreateEditEula from '@/components/Admin/Dialogs/CreateEditEula.vue';
import ReadonlyCheckbox from '@/components/ReadonlyCheckbox.vue';

export default {
  components: { CreateEditEula, ReadonlyCheckbox },
  name: 'eula-details',
  data: () => ({
    loading: true,
    eula: null,
  }),
  methods: {
    async getEula() {
      this.loading = true;
      this.eula = await apiService.getEula(this.$route.params.id);
      this.loading = false;
    },
    editEula() {
      this.$refs.CreateEditEulaRef.editItem(this.eula);
    },
    download() {
      this.$util.downloadAsFile(this.eula.rtfText, this.eula.name + '.rtf');
    },
    bubbleEmit() {
      this.getEula().then(() => {
        this.$emit('eula-updated');
      });
    },
    async deleteEula() {
      if (confirm('Are you sure you want to delete this EULA? This cannot be undone.')) {
        await apiService.deleteEula(this.eula.id);
        this.$router.back();
      }
    },
  },
  created() {
    this.getEula();
  },
};
</script>
